<template>
  <div class="flex items-center space-x-1">
    <div class="relative">
      <lf-avatar :username="userFullName" :size="size" />
      <slot name="extra" />
    </div>
    <span :class="styles.textClass" :style="userFullNameStyle">
      {{ userFullName }}
    </span>
  </div>
</template>

<script
  setup
  lang="ts"
  generic="U extends { first_name?: string; last_name?: string }"
>
import { formatUserFullName } from "@/helpers/formatting";
import { computed } from "vue";
import LfAvatar from "@/components/ui/LFAvatar.vue";

const props = withDefaults(
  defineProps<{
    user: U;
    size?: number;
    textToSizeRatio?: number;
    styles?: { textClass: string };
  }>(),
  {
    size: 24,
    textToSizeRatio: 0.5,
    styles: () => ({ textClass: "text-gray-800" })
  }
);

const userFullName = computed(() => {
  return formatUserFullName(props.user);
});

const userFullNameStyle = computed(() => ({
  fontSize: `${Math.round(props.size * props.textToSizeRatio)}px`
}));
</script>
